import './App.css';
import Login from './Components/Login/Login';
import Register from './Components/Register/Register';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Requests from './Components/Requests/Requests';
import PlayerRequests from './Components/Admin/playerRequests';
import CoachRequests from './Components/Admin/coachRequests';
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from 'react';
import Player from './Components/Requests/Player';
import Coach from './Components/Requests/Coach';

function App() {

  const [role, setRole] = useState("Player");

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decoded = jwtDecode(token);
      setRole(decoded.role);
    }
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/playerRequests" element={<PlayerRequests />} />
        <Route path="/coachRequests" element={<CoachRequests />} />
        <Route path="/pRequests" element={<Player />} />
        <Route path="/cRequests" element={<Coach />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
