import { useEffect, useState } from 'react';
import styles from './Navbar.module.css';
import logo from '../../assets/images/logoNav.png';
import { jwtDecode } from "jwt-decode";

function Navbar() {

  const [role, setRole] = useState("Player");

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decoded = jwtDecode(token);
      setRole(decoded.role);
    }
  }, []);

  // Adding the states
  const [isActive, setIsActive] = useState(false);
  const [selectedLinkIndex, setSelectedLinkIndex] = useState(null);

  // Function to handle link click and update selected state
  const handleLinkClick = (index) => {
    setSelectedLinkIndex(index);
  };

  //add the active class
  const toggleActiveClass = () => {
    setIsActive(!isActive);
  };
  //clean up function to remove the active class
  const removeActive = () => {
    setIsActive(false)
  }

  return (
    <nav className={`${styles.navbar}`}>
      {/* logo */}
      <img src={logo} alt="logo" className={`${styles.logo}`} />

      <ul className={`${styles.navMenu} ${isActive ? styles.active : ''}`}>
        {
          role === 'Admin' ? (
            <>
              <li onClick={() => { handleLinkClick(0); removeActive(); }}>
                <a
                  href="/playerRequests"
                  className={`${styles.navLink} ${selectedLinkIndex === 0 ? styles.selected : ''
                    }`}
                >
                  Player Requests
                </a>
              </li>
              <li onClick={() => { handleLinkClick(1); removeActive(); }}>
                <a
                  href="/coachRequests"
                  className={`${styles.navLink} ${selectedLinkIndex === 1 ? styles.selected : ''
                    }`}
                >
                  Coach Requests
                </a>
              </li>
            </>
          ) : <></>
        }
        <li onClick={() => { handleLinkClick(2); removeActive(); }}>
          <a
            href="/"
            className={`${styles.navLink} ${selectedLinkIndex === 2 ? styles.selected : ''
              }`}
            onClick={() => localStorage.removeItem('token')}
          >
            Logout
          </a>
        </li>
      </ul>

      <div className={`${styles.hamburger} ${isActive ? styles.active : ''}`} onClick={toggleActiveClass}>
        <span className={`${styles.bar}`}></span>
        <span className={`${styles.bar}`}></span>
        <span className={`${styles.bar}`}></span>
      </div>
    </nav>
  );
}

export default Navbar;
