import React, { useEffect, useState } from 'react';
import Navbar from '../Navbar/Navbar';
import './Requests.css';
import Coach from './Coach';
import Player from './Player';
import { jwtDecode } from 'jwt-decode';

function Requests() {

    const [role, setRole] = useState("");

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const decoded = jwtDecode(token);
            setRole(decoded.role);
        }
    }, [])

    return (
        <>
            
            {
                role === "Coach" ? <Coach /> : <Player />
            }

        </>
    );
}

export default Requests;