import React, { useEffect, useState } from 'react';
import { Form, Input, InputNumber, Select, Button, Card, Modal, Table, Tag, Slider, Typography, Switch, message } from 'antd';
import './Requests.css';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import Navbar from '../Navbar/Navbar';

function Player() {

    const { Title } = Typography;

    const columns = [
        {
            title: 'Past Club Name',
            dataIndex: 'pastClubName',
            key: 'pastClubName',
            align: "center"
        },
        {
            title: 'Past Club Position',
            dataIndex: 'pastClubPosition',
            key: 'pastClubPosition',
            align: "center"
        },
        {
            title: 'Position',
            dataIndex: 'playerPosition',
            key: 'playerPosition',
            align: "center"
        },
        {
            title: 'Height',
            dataIndex: 'height',
            key: 'height',
            align: "center"
        },
        {
            title: 'Age',
            dataIndex: 'age',
            key: 'age',
            align: "center"
        },
        {
            title: 'Past Salary',
            dataIndex: 'pastSalary',
            key: 'pastSalary',
            align: "center"
        },
        {
            title: 'Minimum Salary',
            dataIndex: 'minimumSalary',
            key: 'minimumSalary',
            align: "center"
        },
        {
            title: 'New Target',
            dataIndex: 'newTarget',
            key: 'newTarget',
            align: "center"
        },
        {
            title: 'Number Of Years',
            dataIndex: 'numberOfYears',
            key: 'numberOfYears',
            align: "center"
        },
        {
            title: 'Deposit Details',
            dataIndex: 'depositDetails',
            key: 'depositDetails',
            align: "center"
        },
        {
            title: 'National Tournaments',
            key: 'nationalTournaments',
            align: "center",
            render: record => (
                <span>
                    {record.nationalTournaments.map((tournament, index) => (
                        <span key={tournament._id}>
                            {tournament.name} ({tournament.year}){index !== record.nationalTournaments.length - 1 && ' - '}
                        </span>
                    ))}
                </span>
            )
        },
        {
            title: 'Past Clubs',
            dataIndex: 'pastClubsPlayedFor',
            key: 'pastClubsPlayedFor',
            align: "center"
        },
        {
            title: 'New Club Location',
            dataIndex: 'newClubLocation',
            key: 'newClubLocation',
            align: "center"
        },
        // {
        //     title: 'Points',
        //     dataIndex: 'playerPoints',
        //     key: 'playerPoints',
        //     align: "center"
        // }
    ];

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    }

    const handleOk = () => {
        setIsModalVisible(false);
    }

    const handleCancel = () => {
        setIsModalVisible(false);
    }
    const [form] = Form.useForm();

    const onFinish = (values) => {
        console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const [playerRequest, setPlayerRequest] = useState({
        pastClubName: "",
        pastClubPosition: "12",
        playerPosition: "",
        height: 190,
        age: 22,
        pastSalary: 0,
        minimumSalary: 0,
        newTarget: "1-5",
        numberOfYears: "1-5",
        depositDetails: "",
        nationalTournaments: [],
        pastClubsPlayedFor: [],
        newClubLocation: ""
    })

    const [otherClubName, setOtherClubName] = useState(false);

    const [selectedNationalTournaments, setSelectedNationalTournaments] = useState([]);

    const handleSelectChange = (value) => {
        setSelectedNationalTournaments(value);
    }

    const newTargetOnChange = (value) => {
        let newTargetValue = "";
        if (value[0] === value[1]) {
            newTargetValue = value[0];
        }
        else {
            newTargetValue = value.join("-");
        }
        setPlayerRequest({ ...playerRequest, newTarget: newTargetValue });
    }

    const numberOfYearsOnChange = (value) => {
        let numberOfYearsValue = "";
        if (value[0] === value[1]) {
            numberOfYearsValue = value[0];
        }
        else {
            numberOfYearsValue = value.join("-");
        }
        setPlayerRequest({ ...playerRequest, numberOfYears: numberOfYearsValue });
    }

    const [nationalTournamentsYears, setNationalTournamentsYears] = useState([]);

    const [otherClubs, setOtherClubs] = useState([]);

    const [requestCreated, setRequestCreated] = useState(false);

    const addRequest = async () => {

        if (nationalTournamentsYears.length !== selectedNationalTournaments.length) {
            message.error("Please fill all the years for the national tournaments");
            return;
        }

        const otherClubsString = otherClubs?.join(" - ");
        let allClubs = "";
        if (playerRequest.pastClubsPlayedFor === "") {
            allClubs = otherClubsString;
        }
        else if (otherClubsString === "") {
            allClubs = playerRequest.pastClubsPlayedFor;
        }
        else {
            allClubs = playerRequest.pastClubsPlayedFor + " - " + otherClubsString;
        }

        const finalNationalTournaments = selectedNationalTournaments?.map((name, index) => ({
            name,
            year: parseInt(nationalTournamentsYears[index])
        }));

        if (playerRequest.pastClubName === "Other" && otherClubName === true) {
            message.error("Please fill the club name");
            return;
        }

        if (playerRequest.pastClubName === "" || playerRequest.playerPosition === "" || playerRequest.depositDetails === "" || playerRequest.newClubLocation === "" || playerRequest.pastClubsPlayedFor.length === 0 || playerRequest.pastSalary === 0 || playerRequest.minimumSalary === 0) {
            message.error("Please fill all the fields");
            return;
        }

        const headers = { 'Authorization': `Bearer ${localStorage.getItem('token')}` };

        const res = await axios.post('https://api.skytouch-agency.com/api/playerRequests', {
            pastClubName: playerRequest.pastClubName,
            pastClubPosition: playerRequest.pastClubPosition,
            playerPosition: playerRequest.playerPosition,
            height: playerRequest.height,
            age: playerRequest.age,
            pastSalary: playerRequest.pastSalary,
            minimumSalary: playerRequest.minimumSalary,
            newTarget: playerRequest.newTarget,
            numberOfYears: playerRequest.numberOfYears,
            depositDetails: playerRequest.depositDetails,
            nationalTournaments: finalNationalTournaments,
            pastClubsPlayedFor: allClubs,
            newClubLocation: playerRequest.newClubLocation
        }, { headers })

        console.log(res);

        setRequestCreated(!requestCreated);
        setIsModalVisible(false);

    }

    const [tournaments, setTournaments] = useState([]);

    useEffect(() => {
        const getTournaments = async () => {
            const res = await axios.get('https://api.skytouch-agency.com/api/tournaments');
            const formattedData = res.data.map(tournament => ({
                value: tournament.name,
                label: tournament.name
            }));
            setTournaments(formattedData);
            console.log(formattedData);
        }
        getTournaments();
    }, [])

    const [clubs, setClubs] = useState([]);

    const [pastClubs, setPastClubs] = useState([]);

    useEffect(() => {
        const getClubs = async () => {
            const header = { 'Authorization': `Bearer ${localStorage.getItem('token')}` };
            const res = await axios.get('https://api.skytouch-agency.com/api/clubs', { headers: header });
            const formattedData = res.data.map(club => ({
                value: club.name,
                label: club.name
            }));
            setClubs(formattedData);

            const formattedPastClubs = res.data.filter(club => club.name !== "Other").map(club => ({
                value: club.name,
                label: club.name
            }));

            setPastClubs(formattedPastClubs);
        }
        getClubs();
    }, [])


    const [allRequests, setAllRequests] = useState([]);

    useEffect(() => {
        const getRequests = async () => {
            const headers = { 'Authorization': `Bearer ${localStorage.getItem('token')}` };
            const response = await axios.get('https://api.skytouch-agency.com/api/playerRequests/user', { headers });
            console.log(response.data);
            setAllRequests(response.data);
        }
        getRequests();
    }, [requestCreated]);

    return (
        <>
            <Navbar />
            <Card title={
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "130px" }}>
                    <h3>Requests</h3>
                    <Button type="primary" style={{ backgroundColor: "#1677FF" }} onClick={showModal}>Add Request</Button>
                    <Modal title="Add Request" open={isModalVisible} onOk={addRequest} style={{ top: "20px" }}
                        footer={[
                            <Button onClick={handleCancel}>
                                Cancel
                            </Button>,
                            <Button type="primary" onClick={addRequest} style={{ backgroundColor: "#1677FF" }} >
                                Submit
                            </Button>,
                        ]}
                    >
                        <Form
                            form={form}
                            layout="vertical"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            size="large"
                        >
                            {/* Club Details */}
                            <Form.Item label="Past Club Name" name="pastClubName">
                                <Select style={{ width: "100%" }} options={clubs}
                                    onChange={(value) => {
                                        setPlayerRequest({ ...playerRequest, pastClubName: value });
                                        if (value === "Other") {
                                            setOtherClubName(true);
                                        } else {
                                            setOtherClubName(false);
                                        }
                                    }}
                                    defaultValue={playerRequest.pastClubName}
                                />
                                {
                                    otherClubName === true ? <Input placeholder="Enter Club Name" style={{ marginTop: "20px" }} onChange={(e) => setPlayerRequest({ ...playerRequest, pastClubName: e.target.value })} /> : <></>
                                }
                            </Form.Item>
                            <Form.Item label="Past Club Position" name="pastClubPosition">
                                <Slider
                                    min={1}
                                    max={24}
                                    defaultValue={12}
                                    style={{ width: "100%" }}
                                    onChange={(value) => { setPlayerRequest({ ...playerRequest, pastClubPosition: value }) }}
                                />
                            </Form.Item>
                            <Form.Item label="Position" name="playerPosition">
                                <Select style={{ width: "100%" }} options={[
                                    { value: "outsideHitter", label: "Outside Hitter" },
                                    { value: "oppositeHitter", label: "Opposite Hitter" },
                                    { value: "setter", label: "Setter" },
                                    { value: "centreblocker", label: "Centre Blocker" },
                                    { value: "libero", label: "Libero" }
                                ]}
                                    onChange={(value) => { setPlayerRequest({ ...playerRequest, playerPosition: value }) }}
                                />
                            </Form.Item>
                            <Form.Item label="Height" name="height">
                                <Slider
                                    min={150}
                                    max={215}
                                    defaultValue={190}
                                    style={{ width: "100%" }}
                                    onChange={(value) => { setPlayerRequest({ ...playerRequest, height: value }) }}
                                />
                            </Form.Item>
                            <Form.Item label="Age" name="age">
                                <Slider
                                    min={18}
                                    max={45}
                                    defaultValue={22}
                                    style={{ width: "100%" }}
                                    onChange={(value) => { setPlayerRequest({ ...playerRequest, age: value }) }}
                                />
                            </Form.Item>

                            <Form.Item label="Past Salary" name="pastSalary">
                                <InputNumber style={{ width: "100%" }} min={40000} max={1000000} onChange={(value) => { setPlayerRequest({ ...playerRequest, pastSalary: value }) }} />
                            </Form.Item>

                            <Form.Item label="Minimum Salary" name="minimumSalary">
                                <InputNumber style={{ width: "100%" }} min={0} max={500000} onChange={(value) => { setPlayerRequest({ ...playerRequest, minimumSalary: value }) }} />
                            </Form.Item>

                            <Form.Item label="New Target" name="newTarget">
                                <Slider
                                    range
                                    min={1}
                                    max={24}
                                    defaultValue={[1, 5]}
                                    style={{ width: "100%" }}
                                    onChange={newTargetOnChange}
                                />
                            </Form.Item>
                            <Form.Item label="Number Of Years" name="numberOfYears">
                                <Slider
                                    range
                                    min={1}
                                    max={10}
                                    defaultValue={[1, 5]}
                                    style={{ width: "100%" }}
                                    onChange={numberOfYearsOnChange}
                                />
                            </Form.Item>
                            <Form.Item label="Deposit Details" name="depositDetails">
                                <Select style={{ width: "100%" }} options={[
                                    { value: "20%", label: "20%" },
                                    { value: "25%", label: "25%" },
                                    { value: "30%", label: "30%" },
                                    { value: "noDeposit", label: "No Deposit" },
                                    { value: "any", label: "Any" }
                                ]}
                                    onChange={(value) => { setPlayerRequest({ ...playerRequest, depositDetails: value }) }}
                                />
                            </Form.Item>
                            <Form.Item label="National Tournaments" name="nationalTournaments">
                                <div style={{ display: "flex", gap: "10px" }}>
                                    <Select style={{ width: "100%" }} mode='multiple' options={tournaments}
                                        onChange={handleSelectChange}
                                        defaultValue={selectedNationalTournaments}
                                        allowClear
                                    />

                                </div>
                                {
                                    selectedNationalTournaments?.map((tournament, index) => {
                                        return (
                                            <div key={index} style={{ display: "flex", gap: "10px", width: "100%", marginTop: "10px", justifyContent: "center", alignItems: "center" }}>
                                                <Title level={5} style={{ width: "50%" }}>{tournament}</Title>
                                                <div style={{ width: "50%" }}>
                                                    <Title level={5} style={{ width: "100%", textAlign: "center" }}>Year</Title>
                                                    <Input style={{ width: "100%" }} onChange={(e) => {
                                                        const newYears = [...nationalTournamentsYears];
                                                        newYears[index] = e.target.value;
                                                        setNationalTournamentsYears(newYears);
                                                    }} />
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </Form.Item>

                            <Form.Item label="Past Clubs" name="pastClubsPlayedFor">
                                <Select style={{ width: "100%" }} options={pastClubs}
                                    mode='multiple'
                                    allowClear
                                    onChange={(value) => { setPlayerRequest({ ...playerRequest, pastClubsPlayedFor: value.join(" - ") }) }}
                                />
                            </Form.Item>

                            <Form.List
                                name="otherPastClubsPlayedFor"
                            >
                                {(fields, { add, remove }, { errors }) => (
                                    <>
                                        {fields.map((field, index) => (
                                            <Form.Item
                                                label={`Club ${index + 1} `}
                                                required={false}
                                                key={field.key}
                                            >
                                                <Form.Item
                                                    {...field}
                                                    validateTrigger={['onChange', 'onBlur']}
                                                    noStyle
                                                >
                                                    <Input
                                                        placeholder="Club Name"
                                                        style={{
                                                            width: '92%',
                                                        }}
                                                        onChange={(e) => {
                                                            const newClubs = [...otherClubs];
                                                            newClubs[index] = e.target.value;
                                                            setOtherClubs(newClubs);
                                                        }}
                                                    />
                                                </Form.Item>
                                                {fields.length > 0 ? (
                                                    <MinusCircleOutlined
                                                        className="dynamic-delete-button"
                                                        onClick={() => {
                                                            remove(field.name);
                                                            const newClubs = [...otherClubs];
                                                            newClubs.splice(index, 1);
                                                            setOtherClubs(newClubs);
                                                        }}
                                                        style={{ marginLeft: "10px" }}
                                                    />
                                                ) : null}
                                            </Form.Item>
                                        ))}
                                        <Form.Item>
                                            <Button
                                                type="dashed"
                                                onClick={() => add()}
                                                style={{
                                                    width: '100%',
                                                }}
                                                icon={<PlusOutlined />}
                                            >
                                                Add Other Clubs
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>

                            <Form.Item label="New Club Location" name="newClubLocation">
                                <Select style={{ width: "100%" }} options={[
                                    { value: "Cairo", label: "Cairo" },
                                    { value: "Giza", label: "Giza" },
                                    { value: "Alexandria", label: "Alexandria" },
                                    { value: "Any", label: "Any" },
                                ]}
                                    onChange={(value) => { setPlayerRequest({ ...playerRequest, newClubLocation: value }) }}
                                />
                            </Form.Item>
                        </Form>
                    </Modal>
                </div>
            } style={{ width: "100%" }}>
                <Table columns={columns} dataSource={allRequests} style={{ overflow: "auto" }} />
            </Card>
        </>
    )
}

export default Player;