import React, { useEffect, useState } from 'react';
import Navbar from '../Navbar/Navbar';
import { Button, Card, Input, Modal, Popconfirm, Table, Typography, message } from 'antd';
import axios from 'axios';
import moment from "moment";
import { DeleteOutlined } from '@ant-design/icons';

function PlayerRequests() {

    const { Title } = Typography;

    const columns = [
        {
            title: 'Past Club Name',
            dataIndex: 'pastClubName',
            key: 'pastClubName',
            align: "center"
        },
        {
            title: 'Past Club Position',
            dataIndex: 'pastClubPosition',
            key: 'pastClubPosition',
            align: "center"
        },
        {
            title: 'Position',
            dataIndex: 'playerPosition',
            key: 'playerPosition',
            align: "center"
        },
        {
            title: 'Height',
            dataIndex: 'height',
            key: 'height',
            align: "center"
        },
        {
            title: 'Age',
            dataIndex: 'age',
            key: 'age',
            align: "center"
        },
        {
            title: 'Past Salary',
            dataIndex: 'pastSalary',
            key: 'pastSalary',
            align: "center"
        },
        {
            title: 'Minimum Salary',
            dataIndex: 'minimumSalary',
            key: 'minimumSalary',
            align: "center"
        },
        {
            title: 'New Target',
            dataIndex: 'newTarget',
            key: 'newTarget',
            align: "center"
        },
        {
            title: 'Number Of Years',
            dataIndex: 'numberOfYears',
            key: 'numberOfYears',
            align: "center"
        },
        {
            title: 'Deposit Details',
            dataIndex: 'depositDetails',
            key: 'depositDetails',
            align: "center"
        },
        {
            title: 'National Tournaments',
            key: 'nationalTournaments',
            align: "center",
            render: record => (
                <span>
                    {record.nationalTournaments.map((tournament, index) => (
                        <span key={tournament._id}>
                            {tournament.name} ({tournament.year}){index !== record.nationalTournaments.length - 1 && ' - '}
                        </span>
                    ))}
                </span>
            )
        },
        {
            title: 'Past Clubs',
            dataIndex: 'pastClubsPlayedFor',
            key: 'pastClubsPlayedFor',
            align: "center"
        },
        {
            title: 'New Club Location',
            dataIndex: 'newClubLocation',
            key: 'newClubLocation',
            align: "center"
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            align: "center"
        },
        // {
        //     title: 'Points',
        //     dataIndex: 'playerPoints',
        //     key: 'playerPoints',
        //     align: "center"
        // }
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            render: (record) => (
                <div style={{ display: "flex", gap: "10px" }}>
                    <Button type='primary' style={{ backgroundColor: "#1677FF" }} onClick={() => handleOpenModal(record)}>View Details</Button>
                    <Popconfirm
                        title="Delete the request"
                        description="Are you sure to delete this request?"
                        onConfirm={() => confirm(record._id)}
                        okText="Yes"
                        cancelText="No"
                        okButtonProps={{ style: { backgroundColor: "red" } }}
                    >
                        <Button type='primary' style={{ backgroundColor: "red" }} icon={<DeleteOutlined />}></Button>
                    </Popconfirm>
                </div>
            ),
        },
    ];

    const [recordDeleted, setRecordDeleted] = useState(false);

    const confirm = async (id) => {
        await axios.delete(`https://api.skytouch-agency.com/api/playerRequests/${id}`, { headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` } });
        setRecordDeleted(!recordDeleted);
    };

    const [playerRequests, setPlayerRequests] = useState([]);
    const [modalVisible, setModalVisible] = useState(false); // State to manage modal visibility
    const [selectedRecord, setSelectedRecord] = useState(null); // State to store the clicked record

    useEffect(() => {
        const getRequests = async () => {
            const headers = { 'Authorization': `Bearer ${localStorage.getItem('token')}` };
            const res = await axios.get('https://api.skytouch-agency.com/api/playerRequests', { headers })
            const formattedData = res.data.map(request => ({
                ...request,
                createdAt: moment(request.createdAt).subtract(2, 'hours').format('YYYY-MM-DD HH:mm:ss') // Subtract 2 hours
            }));

            setPlayerRequests(formattedData);
        }
        getRequests();
    }, [recordDeleted])

    // Function to handle opening the modal and set the selected record
    const handleOpenModal = (record) => {
        setSelectedRecord(record);
        setModalVisible(true);
    };

    // Function to handle closing the modal
    const handleCloseModal = () => {
        setModalVisible(false);
    };

    const [playerDetails, setPlayerDetails] = useState({});

    useEffect(() => {
        const getPlayerDetails = async () => {
            const headers = { 'Authorization': `Bearer ${localStorage.getItem('token')}` };
            const res = await axios.get(`https://api.skytouch-agency.com/api/users/${selectedRecord.createdBy}`, { headers });
            setPlayerDetails(res.data);
        }
        if (selectedRecord) {
            getPlayerDetails();
        }
    }, [selectedRecord]);

    return (
        <>
            <Navbar />
            <Card title={<h3>Player Requests</h3>} style={{ width: "100%", marginTop: "130px" }}>
                <Table columns={columns} dataSource={playerRequests} style={{ overflow: "auto" }} />
            </Card>
            <Modal
                title="Player Details"
                open={modalVisible}
                onCancel={handleCloseModal}
                footer={null}
                style={{ top: "20px" }}
            >
                {selectedRecord && (
                    <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                        <div>
                            <Title level={5}>Name</Title>
                            <Input readOnly value={playerDetails?.fullName} />
                        </div>
                        <div>
                            <Title level={5}>Email</Title>
                            <Input readOnly value={playerDetails?.email} />
                        </div>
                        <div>
                            <Title level={5}>Mobile Number</Title>
                            <Input readOnly value={playerDetails?.mobileNumber} />
                        </div>
                        <div>
                            <Title level={5}>National ID</Title>
                            <img src={`https://api.skytouch-agency.com/api/images/getImage?name=${playerDetails?.nationalIdImg}`} alt="National ID" style={{ width: "100%" }} />
                        </div>
                    </div>
                )}
            </Modal>
        </>
    );
}

export default PlayerRequests;