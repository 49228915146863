import React, { useEffect, useState } from 'react';
import { Form, Input, InputNumber, Select, Button, Card, Modal, Table, Tag, Slider, Typography, Switch, message } from 'antd';
import './Requests.css';
import axios from 'axios';
import Navbar from '../Navbar/Navbar';

function Coach() {

    const { Title } = Typography;

    const columns = [
        {
            title: 'Club Name',
            dataIndex: 'clubName',
            key: 'clubName',
            align: "center"
        },
        {
            title: 'Club Target',
            dataIndex: 'clubTarget',
            key: 'clubTarget',
            align: "center"
        },
        {
            title: 'Club Last Position',
            dataIndex: 'clubLastPosition',
            key: 'clubLastPosition',
            align: "center"
        },
        {
            title: 'Player Position',
            dataIndex: 'playerPosition',
            key: 'playerPosition',
            align: "center"
        },
        {
            title: 'Player Level',
            dataIndex: 'playerLevel',
            key: 'playerLevel',
            align: "center"
        },
        {
            title: 'Player Height',
            dataIndex: 'playerHeight',
            key: 'playerHeight',
            align: "center"
        },
        {
            title: 'Salary Range',
            dataIndex: 'salaryRange',
            key: 'salaryRange',
            align: "center"
        },
        {
            title: 'Contract Years',
            dataIndex: 'contractNumberOfYears',
            key: 'contractNumberOfYears',
            align: "center"
        },
        {
            title: 'Player Past Club Position',
            dataIndex: 'playerPastClubPosition',
            key: 'playerPastClubPosition',
            align: "center"
        },
        {
            title: 'Player Age',
            dataIndex: 'playerAge',
            key: 'playerAge',
            align: "center"
        }
    ];

    const [form] = Form.useForm();


    const [otherClubName, setOtherClubName] = useState(false);

    const [anyLevelPoints, setAnyLevelPoints] = useState(false);
    const [anyLevelSalary, setAnyLevelSalary] = useState(false);

    const clubTragetOnChange = (value) => {
        let clubTargetValue = "";
        if (value[0] === value[1]) {
            clubTargetValue = value[0];
        }
        else {
            clubTargetValue = value.join("-");
        }
        setCoachRequest({ ...coachRequest, clubTarget: clubTargetValue });
    }

    const playerPointsOnChange = (checked) => {
        setAnyLevelPoints(checked);
        if (checked) {
            form.setFieldsValue({ playerLevel: "Any" });
            setCoachRequest({ ...coachRequest, playerLevel: "Any" });
        }
        else {
            form.setFieldsValue({ playerLevel: "" });
            setCoachRequest({ ...coachRequest, playerLevel: "" });
        }
    }

    const playerHeightOnChange = (value) => {
        let playerHeightValue = "";
        if (value[0] === value[1]) {
            playerHeightValue = value[0];
        }
        else {
            playerHeightValue = value.join("-");
        }
        setCoachRequest({ ...coachRequest, playerHeight: playerHeightValue });
    }

    const playerSalaryOnChange = (checked) => {
        setAnyLevelSalary(checked);
        if (checked) {
            form.setFieldsValue({ salaryRange: "Any" });
            setCoachRequest({ ...coachRequest, salaryRange: "Any" });
        }
        else {
            form.setFieldsValue({ salaryRange: "" });
            setCoachRequest({ ...coachRequest, salaryRange: "" });
        }
    }

    const [minSalry, setMinSalary] = useState(0);
    const [maxSalary, setMaxSalary] = useState(0);


    const minSalaryOnChange = (value) => {
        setMinSalary(value);
        setCoachRequest({ ...coachRequest, salaryRange: value + "-" + maxSalary });
    }

    const maxSalaryOnChange = (value) => {
        setMaxSalary(value);
        setCoachRequest({ ...coachRequest, salaryRange: minSalry + "-" + value });
    }

    const playerAgeOnChange = (value) => {
        let playerAgeValue = "";
        if (value[0] === value[1]) {
            playerAgeValue = value[0];
        }
        else {
            playerAgeValue = value.join("-");
        }
        setCoachRequest({ ...coachRequest, playerAge: playerAgeValue });
    }


    const [coachRequest, setCoachRequest] = useState({
        clubName: "",
        clubTarget: "10-14",
        clubLastPosition: "12",
        playerPosition: "",
        playerLevel: "",
        playerHeight: "190-200",
        salaryRange: "",
        contractNumberOfYears: "4",
        playerPastClubPosition: "",
        playerAge: "18-25"
    })

    const [requestCreated, setRequestCreated] = useState(false);

    const addRequest = async () => {
        if (coachRequest.clubName === "" || coachRequest.playerPosition === "" || coachRequest.playerLevel === "" || coachRequest.playerPastClubPosition === "" || coachRequest.salaryRange === "") {
            message.error('Please fill all fields');
            return;
        }
        const headers = { 'Authorization': `Bearer ${localStorage.getItem('token')}` };
        await axios.post('https://api.skytouch-agency.com/api/coachRequests', coachRequest, { headers });
        form.resetFields();
        setRequestCreated(!requestCreated);
        setIsModalVisible(false);
    }

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    }

    const handleCancel = () => {
        setIsModalVisible(false);
    }

    const [clubs, setClubs] = useState([]);

    useEffect(() => {
        const getClubs = async () => {
            const header = { 'Authorization': `Bearer ${localStorage.getItem('token')}` };
            const res = await axios.get('https://api.skytouch-agency.com/api/clubs', { headers: header });
            const formattedData = res.data.map(club => ({
                value: club.name,
                label: club.name
            }));
            setClubs(formattedData);
            console.log(formattedData);
        }
        getClubs();
    }, [])


    const [allRequests, setAllRequests] = useState([]);

    useEffect(() => {
        const getRequests = async () => {
            const headers = { 'Authorization': `Bearer ${localStorage.getItem('token')}` };
            const response = await axios.get('https://api.skytouch-agency.com/api/coachRequests/user', { headers });
            console.log(response.data);
            setAllRequests(response.data);
        }
        getRequests();
    }, [requestCreated]);

    return (
        <>
            <Navbar />
            <Card title={
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "130px" }}>
                    <h3>Requests</h3>
                    <Button type="primary" style={{ backgroundColor: "#1677FF" }} onClick={showModal}>Add Request</Button>
                    <Modal title="Add Request" open={isModalVisible} style={{ top: "20px" }}
                        footer={[
                            <Button onClick={handleCancel}>
                                Cancel
                            </Button>,
                            <Button type="primary" onClick={addRequest} style={{ backgroundColor: "#1677FF" }} >
                                Submit
                            </Button>,
                        ]}
                    >
                        <Form
                            form={form}
                            layout="vertical"
                            size="large"
                        >
                            {/* Club Details */}
                            <Form.Item label="My Club" name="clubName">
                                <Select style={{ width: "100%" }} options={clubs}
                                    onChange={(value) => {
                                        setCoachRequest({ ...coachRequest, clubName: value });
                                        if (value === "other") {
                                            setOtherClubName(true);
                                        } else {
                                            setOtherClubName(false);
                                        }
                                    }}
                                    defaultValue={coachRequest.clubName}
                                />
                                {
                                    otherClubName === true ? <Input placeholder="Enter Club Name" style={{ marginTop: "20px" }} onChange={(e) => setCoachRequest({ ...coachRequest, clubName: e.target.value })} /> : <></>
                                }
                            </Form.Item>
                            <Form.Item label="Club Target" name="clubTarget">
                                <Slider
                                    range
                                    min={1}
                                    max={24}
                                    defaultValue={[10, 14]}
                                    style={{ width: "100%" }}
                                    onChange={clubTragetOnChange}
                                />
                            </Form.Item>
                            <Form.Item label="Club Last Position" name="clubLastPosition">
                                <Slider
                                    min={1}
                                    max={24}
                                    defaultValue={12}
                                    style={{ width: "100%" }}
                                    onChange={(value) => { setCoachRequest({ ...coachRequest, clubLastPosition: value }) }}
                                />
                            </Form.Item>

                            {/* Player Details */}
                            <Form.Item label="Player Position" name="playerPosition">
                                <Select style={{ width: "100%" }} options={[
                                    { value: "outsideHitter", label: "Outside Hitter" },
                                    { value: "oppositeHitter", label: "Opposite Hitter" },
                                    { value: "setter", label: "Setter" },
                                    { value: "centreblocker", label: "Centre Blocker" },
                                    { value: "libero", label: "Libero" }
                                ]}
                                    onChange={(value) => { setCoachRequest({ ...coachRequest, playerPosition: value }) }}
                                />
                            </Form.Item>
                            <Form.Item label="Player Level (Points)" name="playerLevel">
                                <div style={{ display: "flex", gap: "10px", justifyContent: "center", alignItems: "center" }}>
                                    <InputNumber style={{ width: "100%" }} disabled={anyLevelPoints} onChange={(value) => { setCoachRequest({ ...coachRequest, playerLevel: value }) }} />
                                    <Switch style={{ width: "25%", backgroundColor: anyLevelPoints ? "#1677FF" : "#BFBFBF", }} title='ANY' checkedChildren="ANY" unCheckedChildren="ANY" onChange={playerPointsOnChange} />
                                </div>
                            </Form.Item>
                            <Form.Item label="Player Height (cm)" name="playerHeight">
                                <Slider
                                    range
                                    min={160}
                                    max={215}
                                    defaultValue={[190, 200]}

                                    style={{ width: "100%" }}
                                    onChange={playerHeightOnChange}
                                />
                            </Form.Item>
                            <Form.Item label="Salary Range" name="salaryRange">
                                <div style={{ display: "flex", gap: "10px" }}>
                                    <div style={{ width: "100%" }}>
                                        <Title level={5}>From</Title>
                                        <InputNumber style={{ width: "100%" }} min={0} max={200000} onChange={minSalaryOnChange} disabled={anyLevelSalary} />
                                    </div>
                                    <div style={{ width: "100%" }}>
                                        <Title level={5}>To</Title>
                                        <InputNumber style={{ width: "100%" }} min={200001} max={1000000} onChange={maxSalaryOnChange} disabled={anyLevelSalary} />
                                    </div>
                                    <div style={{ width: "50%" }}>
                                        {/* <Title level={5}>Any</Title> */}
                                        <Switch style={{ width: "100%", backgroundColor: anyLevelSalary ? "#1677FF" : "#BFBFBF", }} title='ANY' checkedChildren="ANY" unCheckedChildren="ANY" onChange={playerSalaryOnChange} />
                                    </div>
                                </div>
                            </Form.Item>
                            <Form.Item label="Contract Duration (Years)" name="contractYears">
                                <Slider
                                    min={1}
                                    max={10}
                                    defaultValue={4}
                                    style={{ width: "100%" }}
                                    onChange={(value) => { setCoachRequest({ ...coachRequest, contractYears: value }) }}
                                />
                            </Form.Item>
                            <Form.Item label="Player Past Club Position (Range)" name="pastClubPositionRange">
                                <Select style={{ width: "100%" }} options={[
                                    { value: "1-5", label: "1-5" },
                                    { value: "6-10", label: "6-10" },
                                    { value: "11-16", label: "11-16" },
                                    { value: "17-24", label: "17-24" },
                                    { value: "any", label: "Any" }
                                ]}
                                    onChange={(value) => { setCoachRequest({ ...coachRequest, playerPastClubPosition: value }) }}
                                />
                            </Form.Item>
                            <Form.Item label="Player Age" name="playerAge">
                                <Slider
                                    range
                                    min={18}
                                    max={45}
                                    defaultValue={[18, 25]}
                                    style={{ width: "100%" }}
                                    onChange={playerAgeOnChange}
                                />
                            </Form.Item>
                        </Form>
                    </Modal>
                </div>
            } style={{ width: "100%" }}>
                <Table columns={columns} dataSource={allRequests} style={{ overflow: "auto" }} />
            </Card>
        </>
    )
}

export default Coach;